
<template>
    <el-empty description="太久没有操作了，请重新登录，2秒后自动跳转到登录页面 (゜-゜)つロ">
        <el-button type="primary" @click="gotoLogin">回家</el-button>
    </el-empty>
</template>
<script>
export default {
    data(){
        return{
            query:{},
            timer:'',
            value: 0
        }
    },
    
    mounted() {
      console.log("执行setTimeout");
      this.timer = setTimeout(this.gotoLogin, 3000);//只执行一次
    },
    beforeDestroy() {
      clearTimeout(this.timer);
    },
    methods: {
        gotoLogin(){
            //console.log("gotoLogin");
            //通过push进行跳转
            // this.value ++;
            // console.log(this.value);
            window.sessionStorage.clear();
            this.$router.push('/login')
        }
    }
}
</script>
<style lang="less" scoped>
</style>